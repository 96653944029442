// extracted by mini-css-extract-plugin
export var column = "SignUpError__column__HKtgH";
export var fieldError = "SignUpError__fieldError__y0z8A";
export var flex = "SignUpError__flex__CnENy";
export var flexColumn = "SignUpError__flexColumn__Nc27J";
export var gap1 = "SignUpError__gap1__Iyyuo";
export var gap2 = "SignUpError__gap2__pG9ns";
export var gap3 = "SignUpError__gap3__H3Jx5";
export var gap4 = "SignUpError__gap4__HsKa4";
export var gap5 = "SignUpError__gap5__jiYtY";
export var row = "SignUpError__row__QZdwZ";